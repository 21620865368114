.ant-layout .ant-spin {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(16, 142, 233, 0.1);
}
.ant-layout .ant-spin-nested-loading > div > .ant-spin {
  max-height: 562px;
}
